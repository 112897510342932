* {
  box-sizing: border-box;
}

.wrapper-content {
  background-color: #fff;
  padding: 15px;
}

.wrapper-search {
  margin-bottom: 15px;
}

.wrapper-table {
  min-height: 100vh;
}

.wrapper-table th {
  border-right: 1px solid #f1f1f1;
  padding: 0 5px !important;
  text-align: center !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  height: 30px !important;
  overflow: hidden;
}

.wrapper-table td {
  border: 1px solid #f1f1f1;
  padding: 0px !important;
  text-align: center;
  height: 1px !important;
}

.wrapper-table td .truncate {
  font-size: 16px;
  padding: 0px 10px;
}

.wrapper-table td .ant-tag {
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 5px 10px;
}

.wrapper-table tr:nth-child(even) {
  background-color: #f2f2f2 !important;
}

.wrapper-table tr:hover {
  opacity: 0.8 !important;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.comment-link {
  display: flex;
  color: #000;
  padding-left: 0;
}

.comment-link .truncate {
  width: 120px;
  font-size: 14px !important;
}

.comment-link .anticon {
  font-size: 20px;
}

.empty-comment {
  color: #444444d9;
  font-style: italic;
}

.ant-popover-content {
  max-width: 400px;
}

#components-table-demo-resizable-column .react-resizable {
  position: relative;
  background-clip: padding-box;
}

#components-table-demo-resizable-column .react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.container-bracrum {
  margin-top: 70px;
  padding: 0.5rem 1rem;
}

.wrapper_container {
  padding: 0.5rem 1rem;

  display: grid;
  grid-template-columns: auto 300px;
}

.container_breadcrumb {
  padding: 1rem;
}

.space-right {
  margin-left: 10px;
}

/* .table {
    border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  
  } */
.container-table {
  padding: 1rem;
}

.right_container {
  width: 40%;
  text-align: end;
}

.tree_selection {
  width: 300px;
  min-height: 400px;
  overflow: scroll;
}

.ant-select-selection-overflow {
  margin-bottom: 2px;
}

button.ant-btn.ant-btn-text {
  width: 100px;
}

.ant-space-item {
  color: black;
}

.send_css {
  color: red;
}

.s_footer {
  display: flex;
  justify-content: space-between;
}

.bul_confirm {
  text-align: start !important;
}

.text_top {
  vertical-align: 1cm;
}

.span_churn {
  text-align: justify;
}

.hide-checkbox .ant-select-tree-checkbox.ant-select-tree-checkbox-disabled {
  display: none;
}

.custom-ok-btn-hrpr {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 6px 6px 8px;
  color: white !important;
  width: 150px;
  // height: 48px;
  background: #5440b8 !important;
  border-color: #5440b8 !important;

  border-radius: 4px;
}

.custom-cancel-btn-hrpr {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 6px 6px 8px;
  color: black !important;
  width: 150px;
  // height: 48px;
  border-color: gray;
  border: 1px solid;
  border-radius: 4px;
}

.custom-ok-btn:hover {
  opacity: 0.8 !important;
  border-color: #5440b8;
}

.custom-cancel-btn:hover {
  opacity: 0.8 !important;
  border-color: gray !important;
}
.custom-empty {
  color: #5440b8;
}

.title_confirm {
  font-size: 14px;
}

.confirm_ul li {
  font-size: 14px;
}

.icon_info {
  margin-left: 5px;
}

.style_bulknow {
  span.ant-table-column-title {
    text-align: center;
  }
}

.style-notified {
  text-align: end;
}

ul.ant-pagination.ant-pagination-mini.ant-table-pagination.ant-table-pagination-right {
  position: absolute !important;
  left: 0px;
}

.action_select {
  margin-left: -20px;
}

.style_search {
  position: absolute;
  right: 20px;
}

.custom_feedbackhrpr {
  position: relative;
}

.feedback_childhrpr {
  position: fixed;

  width: 212px;
  height: 40px;
  border-top: 5px solid #6e2aff;
  right: 10px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  bottom: 10px;
  background: #ffffff;
}

.css_svghrpr {
  position: absolute;
  right: -100px;
  margin-top: 5px;
}
