.wrapper_create {
  margin-top: 50px;
}
.custom_feedback_employ {
  position: relative;
}

.feedback_child_employ {
  position: fixed;

  width: 212px;
  height: 40px;
  border-top: 5px solid #6e2aff;
  right: 10px;
  bottom: 10px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  background: #ffffff;
}

.css_svg {
  position: absolute;
  right: -100px;
  margin-top: 5px;
}
.control_add {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  min-height: 100vh;
}

.screen {
  /* background: linear-gradient(90deg, #5D54A4, #7C78B8);		 */
  position: relative;
  height: 600px;
  width: 442px;
  /* box-shadow: 0px 0px 24px #5C5696; */
}

.screen__content {
  z-index: 1;
  position: relative;
  height: 100%;
}
