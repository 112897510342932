.loading-global {
  background: rgba(255, 255, 255, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding-top: 30vh;
  z-index: 99999999;
  text-align: center;
}

.loading-global .loading {
  display: block;
  margin: auto;
  width: 100px;
  height: auto;
  background: transparent;
}

.loading-global .loading img {
  width: 100px;
  height: auto;
  background: transparent;
}
.loading_global {
  display: flex;
  justify-content: center;
  align-items: center;
}
