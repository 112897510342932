* {
  box-sizing: border-box;
}

.wrapper-content {
  background-color: #fff;
  padding: 15px;
}

.wrapper-search {
  margin-bottom: 15px;
}

.wrapper-table {
  min-height: 80vh;
}

.wrapper-table th {
  border-right: 1px solid #f1f1f1;
  padding: 0 5px !important;
  text-align: center !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  height: 30px !important;
  overflow: hidden;
}

.wrapper-table td {
  border: 1px solid #f1f1f1;
  padding: 0px !important;
  text-align: center;
  height: 1px !important;
}

.wrapper-table td .truncate {
  font-size: 16px;
  padding: 0px 10px;
}

.wrapper-table td .ant-tag {
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 5px 10px;
}
.wrapper-table tr:nth-child(even) {
  background-color: #f2f2f2 !important;
}

.wrapper-table tr:hover {
  opacity: 0.8 !important;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.comment-link {
  display: flex;
  color: #000;
  padding-left: 0;
}

.comment-link .truncate {
  width: 120px;
  font-size: 14px !important;
}

.comment-link .anticon {
  font-size: 20px;
}

.empty-comment {
  color: #444444d9;
  font-style: italic;
}

.ant-popover-content {
  max-width: 400px;
}
#components-table-demo-resizable-column .react-resizable {
  position: relative;
  background-clip: padding-box;
}

#components-table-demo-resizable-column .react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}
.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}
.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}
.container-bracrum {
  margin-top: 70px;
  padding: 0.5rem 1rem;
}

.container_breadcrumb {
  padding: 1rem;
}
.space-right {
  margin-left: 10px;
}

.container-table {
  padding: 1rem;
}
.right_container {
  width: 40%;
  text-align: end;
}
.tree_selection {
  width: 300px;
  min-height: 400px;
  overflow: scroll;
}
.ant-select-selection-overflow {
  margin-bottom: 2px;
}
button.ant-btn.ant-btn-text {
  width: 100px;
}
.ant-space-item {
  color: black;
}
.send_css {
  color: red;
}
.s_footer {
  display: flex;
  justify-content: space-between;
}
.bul_confirm {
  text-align: center;
}
.text_top {
  vertical-align: 1cm;
}

.span_churn {
  text-align: justify;
}
.green-column {
  background: #e1f5fe !important;
}

.wrapper_alter {
  padding: 0px 15px;
}

div.text_custom {
  margin-top: 5px;
  text-align: justify;
}
.custom_logo {
  margin-left: 50px;
  margin-top: 4px;
}
.icon_info {
  margin-left: 5px;
}
.icon_infos {
  margin-left: 20px;
}

.yellow-column {
  background: #f4fcff !important;
}
.ant-modal-confirm-btns {
  // display: flex;
  // justify-content: center;
}
.custom-ok-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 6px 6px 8px;
  color: white !important;
  width: 150px;
  height: 48px;
  background: #5440b8 !important;
  border-color: #5440b8 !important;
  border-radius: 4px;
}
.custom-cancel-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 6px 6px 8px;
  color: white !important;
  width: 150px;
  height: 48px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    #ff4d49 !important;
  border: 1px solid;
  border-radius: 4px;
}
.custom-ok-btn:hover {
  opacity: 0.8 !important;
}
.custom-cancel-btn:hover {
  opacity: 0.8 !important;
}
.title_confirm {
  font-size: 14px;
}
.confirm_ul li {
  font-size: 14px;
}
.greens-column {
  background: #e5f9ff !important ;
}

.h2_title {
  text-align: center;
  letter-spacing: 1px;
}
.yes_cls {
  color: #5440b8;
  letter-spacing: 0.15px;
}
.no_cls {
  color: #d13f3c;
}
.ul_cls li {
  margin: 10px 0px;
}
.hide-checkbox .ant-select-tree-checkbox.ant-select-tree-checkbox-disabled {
  display: none;
}

ul.ant-pagination.ant-pagination-mini.ant-table-pagination.ant-table-pagination-right {
  position: absolute !important;
  left: 0px;
}
.style-notified {
  text-align: end;
}

.icon_info_upload {
  position: absolute;
  right: 10px;
  bottom: 13px;
}
.space_upload {
  .ant-space-item {
    color: #5440b8;
  }
}

.collapse-container {
  z-index: 2000 !important;
}
.custom_feedback {
  position: relative;
}
.feedback_child {
  right: 20px;
  position: fixed;

  width: 212px;
  height: 40px;
  border-top: 5px solid #6e2aff;
  bottom: 10px;
  top: 10px;
  box-shadow:
    0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;

  background: #ffffff;
}
.css_svg_bul {
  position: absolute;
  right: -100px;
  margin-top: 5px;
}
.wrapper_container {
  padding: 0.5rem 1rem;
  display: grid;
  grid-template-columns: 1fr 300px;
}
.style_loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .wrapper_container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 1rem;
  }

  .wrapper_container > div:first-child {
    grid-row: 1;
  }

  .wrapper_container > div:last-child {
    grid-row: 2;
  }
}
