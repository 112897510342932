@import "~antd/dist/antd.css";

@font-face {
  font-family: "roboto";
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/Roboto-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "roboto";
  font-style: normal;
  font-weight: 100;
  src: url(../fonts/Roboto-Thin.ttf) format("opentype");
}

// @font-face {
//   font-family: "roboto";
//   font-style: normal;
//   font-weight: 100;
//   src: url(../fonts/Roboto-ThinItalic.ttf) format("opentype");
// }

@font-face {
  font-family: "roboto";
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/Roboto-Light.ttf) format("opentype");
}

// @font-face {
//   font-family: "roboto";
//   font-style: normal;
//   font-weight: 300;
//   src: url(../fonts/Roboto-LightItalic.ttf) format("opentype");
// }

@font-face {
  font-family: "roboto";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Roboto-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "roboto";
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/Roboto-Medium.ttf) format("opentype");
}
// @font-face {
//   font-family: "roboto";
//   font-style: normal;
//   font-weight: 500;
//   src: url(../fonts/Roboto-MediumItalic.ttf) format("opentype");
// }

@font-face {
  font-family: "roboto";
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/Roboto-Bold.ttf) format("opentype");
}
// @font-face {
//   font-family: "roboto";
//   font-style: normal;
//   font-weight: 700;
//   src: url(../fonts/Roboto-BoldItalic.ttf) format("opentype");
// }
@font-face {
  font-family: "roboto";
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/Roboto-Black.ttf) format("opentype");
}

// @font-face {
//   font-family: "roboto";
//   font-style: normal;
//   font-weight: 900;
//   src: url(../fonts/Roboto-BlackItalic.ttf) format("opentype");
// }

@media screen and (max-width: 992px) {
  .wrapper-about {
    padding: 30px 15px !important;
  }

  .wraper {
    width: 100%;
    padding: 0 15px;
  }
}

@media screen and (max-width: 768px) {
  .title-welcome {
    font-size: 26px;
    font-weight: 700;
  }

  .header-main {
  }

  .header-main .title-header {
    text-align: center;
    margin-bottom: 15px;
  }

  .header-main .button-submit {
    display: block;
    margin: auto;
  }

  .wrapper-about {
    padding: 30px 15px !important;
  }

  .container-step {
    padding: 15px;
  }

  .wrapper-step {
    padding: 30px 5px;
  }

  .wrapper-step .title-step {
    font-size: 22px;
  }

  .wrapper-step .step {
    font-size: 16px;
    margin-top: 5px;
  }
}

@media screen and (max-width: 480px) {
}
